import React from 'react';
import { Tooltip } from "@material-ui/core";
import PropTypes from 'prop-types';

const TooltipText = ({ 
  text, 
  maxLength = 50,
  placement = "top",
  showTooltip = true,
  tooltipStyles = {},
  textStyles = {}
}) => {
  if (!text) return null;
  
  const displayText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  const shouldShowTooltip = showTooltip && text.length > maxLength;

  const defaultTooltipStyles = {
    fontSize: '14px',
    maxWidth: '500px',
    whiteSpace: 'pre-wrap',
    padding: '8px',
    userSelect: 'text',
    cursor: 'text',
    backgroundColor: '#f8f9fa',
    ...tooltipStyles
  };

  const defaultTextStyles = {
    cursor: 'pointer',
    color: 'black',
    fontFamily: 'inherit',
    ...textStyles
  };

  if (!shouldShowTooltip) {
    return <span style={defaultTextStyles}>{displayText}</span>;
  }

  return (
    <Tooltip 
      title={text}
      placement={placement}
      arrow
      interactive
      style={defaultTooltipStyles}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            userSelect: "text",
            cursor: "text"
          }
        }
      }}
    >
      <span style={defaultTextStyles}>{displayText}</span>
    </Tooltip>
  );
};

TooltipText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  showTooltip: PropTypes.bool,
  tooltipStyles: PropTypes.object,
  textStyles: PropTypes.object
};

export default TooltipText; 